<template>
  <div class="yiyu-empty">
    <img src="@/assets/images/page.png" alt="" width="161" />
    <div class="txt">
      <slot>暂无数据</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'empty',
}
</script>

<style lang="less" scoped>
.yiyu-empty {
  text-align: center;
  margin: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  .txt {
    margin-top: 26px;
    color: @textColor;
    // opacity: 0.4;
  }
}
</style>

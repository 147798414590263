import { isWeiXin } from '@/utils/weixin'

// 表单校验规则
export const rules = {
  methods: {
    yiyuPhone(val) {
      if (val.length == 0) return true
      const reg = /^[1][0-9]{10}$/
      return reg.test(val.trim())
    },
  },
}

// 文件上传类型限制
export const acceptType = {
  methods: {
    acceptType(val) {
      switch (val) {
        case 'img':
          // return '.png,.jpg,.jpeg' // 这么写微信浏览器识别不了
          return 'image/*'
      }
    },
  },
}

// 表格通用方法
export const table = {
  methods: {
    getHeader(obj) {
      let arr = []
      for (let key in obj) {
        arr.push({
          prop: key,
          label: obj[key],
        })
      }
      return arr
    },
    headerCellClassName({ columnIndex }) {
      if (columnIndex === 0) {
        return 'all-table-header-bgc first-header-cell'
      } else {
        return 'all-table-header-bgc'
      }
    },

    cellClassName({ columnIndex }) {
      if (columnIndex === 0) {
        return `first-cell`
      } else {
        return 'last-cell'
      }
    },

    rowClassName({ rowIndex }) {
      if (rowIndex % 2) {
        return 'table-utils even-row'
      } else {
        return 'table-utils'
      }
    },

    // 表格高度加上页面其他元素高度不超过视图高度
    setHeight(
      data,
      _this,
      {
        ref = 'table',
        header = '.el-table__header',
        body = '.el-table__body',
        otherHeight = 210,
      } = {},
    ) {
      let tableHeight = 'auto'
      // 1.当表格的body高于视图高度时
      // tableHeight = 获取视图的高度 - 可滑动的高度 - 视图内其他的高度
      if (_this.$refs[ref]) {
        const offsetTop =
          window.innerHeight - _this.$refs[ref].$el.offsetTop - otherHeight
        const offsetBodyHeight =
          (document.querySelector(body) &&
            document.querySelector(body).offsetHeight) ||
          0
        const offsetHeaderHeight =
          (document.querySelector(header) &&
            document.querySelector(header).offsetHeight) ||
          0

        if (data.length && offsetBodyHeight < offsetTop) {
          // 2. 当表格的body不高于视图高度时
          // tableHeight = .el-table__body的高度 + .el-table__header的高度
          tableHeight = offsetBodyHeight + offsetHeaderHeight
        } else if (!data.length) {
          // 3、当没有数据的时候
          // tableHeight = null
          tableHeight = null
        } else {
          tableHeight = offsetTop
        }
      }

      return tableHeight
    },

    // 表格高度最大不能超过视图高度的一半
    setHalfHeight(
      data,
      _this,
      {
        ref = 'table',
        header = '.el-table__header',
        body = '.el-table__body',
      } = {},
    ) {
      let tableHeight = 'auto'

      if (_this.$refs[ref]) {
        const offsetBodyHeight = document.querySelector(body).offsetHeight
        const offsetHeaderHeight = document.querySelector(header).offsetHeight

        if (data.length && offsetBodyHeight < window.innerHeight * 0.5) {
          // 2. 当表格的body不高于视图高度时
          // tableHeight = .el-table__body的高度 + .el-table__header的高度
          tableHeight = offsetBodyHeight + offsetHeaderHeight
        } else if (!data.length) {
          // 3、当没有数据的时候
          // tableHeight = null
          tableHeight = null
        } else {
          // 1.当表格的body高于视图高度时
          tableHeight = window.innerHeight * 0.5
        }
      }

      return tableHeight
    },

    // 表格高度最大不能超过视图高度
    setAllHeight(
      data,
      _this,
      {
        ref = 'table',
        header = '.el-table__header',
        body = '.el-table__body',
      } = {},
    ) {
      let tableHeight = 'auto'

      if (_this.$refs[ref]) {
        const offsetBodyHeight = document.querySelector(body).offsetHeight
        const offsetHeaderHeight = document.querySelector(header).offsetHeight

        if (data.length && offsetBodyHeight < window.innerHeight) {
          // 2. 当表格的body不高于视图高度时
          // tableHeight = .el-table__body的高度 + .el-table__header的高度
          tableHeight = offsetBodyHeight + offsetHeaderHeight
        } else if (!data.length) {
          // 3、当没有数据的时候
          // tableHeight = null
          tableHeight = null
        } else {
          // 1.当表格的body高于视图高度时
          tableHeight = window.innerHeight
        }
      }

      return tableHeight
    },

    exportTable() {
      if (isWeiXin()) {
        alert(
          '微信浏览器无法使用下载功能，如需导出，请点击右上角使用其他浏览器打开后进行导出下载。',
        )
      }
      return
    },
  },
}

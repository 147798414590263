<template>
  <!-- @mousemove="onTouchMove" -->
  <!-- ref="dragRef" -->
  <!-- :style="{ top: pos.y, left: pos.x }" -->
  <!-- @touchmove.stop="onTouchMove" -->
  <div v-if="!newPosition" class="drag__wrapper" v-draggable="'85,85'">
    <div class="add-btn" v-if="slotName == 'add'">
      <van-icon name="add" class="dragable" @click="$emit('add')" />
    </div>
    <transition name="fade">
      <div class="add-btn" v-if="slotName == 'top' && topShow">
        <van-icon
          name="upgrade"
          class="dragable"
          @click="top"
          color="#52627C"
        />
      </div>
    </transition>
    <slot />
  </div>
  <div v-else class="drag__wrapper" v-draggable="'85,75'">
    <div class="add-btn" v-if="slotName == 'add'">
      <van-icon name="add" class="dragable" @click="$emit('add')" />
    </div>
    <transition name="fade">
      <div class="add-btn" v-if="slotName == 'top' && topShow">
        <van-icon
          name="upgrade"
          class="dragable"
          @click="top"
          color="#52627C"
        />
      </div>
    </transition>
    <slot />
  </div>
</template>

<script>
import draggable from 'yiyu-vant-ui/lib/_directive/draggable';
export default {
  props: {
    position: {
      type: Object,
      default: () => ({
        // x: '300px',
        // y: '500px',
        x: '80vw',
        y: '80vh',
      }),
    },
    newPosition: {
      type: Boolean,
      default: false,
    },
    // 触发的事件与slotName一样
    // add - 显示加号 top - 返回顶部 可以直接插东西
    slotName: {
      type: String,
      default: 'add',
    },
    // 如果为 app , 则起用新模式
    mode: {
      type: String,
      default: '',
    },
    target: {
      require: false,
    },
  },
  directives: {
    draggable,
  },
  data() {
    return {
      flags: false,
      pos: {
        x: 0,
        y: 0,
      },
      topShow: false,
      scrollTop: 0,
      timer: undefined,
      scrollTarget: undefined,
      intersectionInstance: null,
      xxx: '85,85',
    };
  },
  mounted() {
    if (this.slotName == 'top') {
      // 加上第三个参数才能监听得到，true为捕获
      window.addEventListener('scroll', this.scrollToTop, true);
    }
  },
  created() {
    if (this.mode === 'app') {
      this.intersectionInstance = new IntersectionObserver(([e]) => {
        e.isIntersecting ? (this.topShow = false) : (this.topShow = true);
      });
      this.intersectionInstance.observe(document.querySelector('#app'));
    }
  },
  destroyed() {
    if (this.slotName == 'top') {
      window.removeEventListener('scroll', this.scrollToTop, true);
    }
    if (this.mode === 'app') {
      this.intersectionInstance.unobserve(document.querySelector('#app'));
      this.intersectionInstance = null;
    }
  },
  methods: {
    top() {
      if (this.mode === 'app') {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        return;
      }
      this.timer = setInterval(() => {
        let ispeed = Math.floor(-this.scrollTop / 5);
        this.scrollTarget.scrollTop = this.scrollTop + ispeed;
        if (this.scrollTop === 0) {
          this.timer && clearInterval(this.timer);
        }
      }, 16);
    },
    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop(e) {
      if (this.mode === 'app') return;
      this.scrollTarget = this.target || e.target;
      if (this.scrollTarget.scrollTop > 0) {
        this.topShow = true;
      } else {
        this.topShow = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.drag__wrapper {
  position: fixed;
  z-index: 5;
}

.add-btn {
  width: 2.5rem;
  height: 2.5rem;
  background: #fff;
  border-radius: 50%;
}

.dragable {
  font-size: 2.5rem;
  color: @mainColor;
}

.van-icon-upgrade {
  opacity: 0.6;
}
</style>
